<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
  >
    <div v-if="detail" class="dialog-wrapper">
      <div class="group">
        <div class="group-title">联系信息</div>
        <div class="info-item">
          <div class="info-item-label">联系人：</div>
          <div class="info-item-value">{{ detail.contactUser }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">联系电话：</div>
          <div class="info-item-value">{{ detail.contactPhone }}</div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">项目信息</div>
        <div class="info-item">
          <div class="info-item-label">求助标题：</div>
          <div class="info-item-value">{{ detail.title }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">求助人故事：</div>
          <div class="info-item-value desc">{{ detail.description }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">项目图片：</div>
          <div class="info-item-value">
            <div class="img-list">
              <el-image
                v-for="item in detail.projectFileList"
                :key="item.id"
                style="width: 70px; height: 70px"
                :src="item.fileUrl"
                :preview-src-list="projectFileList"
                :z-index="9999"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">资料说明</div>
        <div class="group-double-row">
          <div class="info-item">
            <div class="info-item-label">求助人：</div>
            <div class="info-item-value">{{ detail.userName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">参军时间：</div>
            <div class="info-item-value">{{ detail.joinArmyTime }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">原因：</div>
            <div class="info-item-value">{{ detail.difficultReasonName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">退伍时间：</div>
            <div class="info-item-value">{{ detail.leaveArmyTime }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">收款人：</div>
            <div class="info-item-value">{{ detail.receiver }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">所属军队：</div>
            <div class="info-item-value">{{ detail.troopsName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">收款账户：</div>
            <div class="info-item-value">{{ detail.receiveAccout }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">军种：</div>
            <div class="info-item-value">{{ detail.soldierTypeName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">与求助人关系：</div>
            <div class="info-item-value">{{ detail.relationName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">军阶：</div>
            <div class="info-item-value">{{ detail.soldierRank }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">目标筹款：</div>
            <div class="info-item-value">{{ detail.targetMoney }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">荣誉：</div>
            <div class="info-item-value">{{ detail.honour }}</div>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">详情说明</div>
        <div class="info-item">
          <div class="info-item-label">详情说明：</div>
          <div class="info-item-value">{{ detail.difficultDetail }}</div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">求助人家庭收入状况</div>
        <div class="group-double-row">
          <div class="info-item">
            <div class="info-item-label">家庭经济状况：</div>
            <div class="info-item-value">{{ detail.familyEconomicStatus }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">个人保障状况：</div>
            <div class="info-item-value">{{ detail.personalSafeguardStatus }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">家庭房屋财产状况：</div>
            <div class="info-item-value">{{ detail.familyHouseStatus }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">是否享受政府资助：</div>
            <div class="info-item-value">{{ detail.enableGovernmentHelpName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">家庭车辆财产状况：</div>
            <div class="info-item-value">{{ detail.familyCarStatus }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">是否低保户：</div>
            <div class="info-item-value">{{ detail.enableLowHelpName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">家庭债务状况：</div>
            <div class="info-item-value">{{ detail.familyDebtStatus }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">是否在其他平台发起筹款：</div>
            <div class="info-item-value">{{ detail.enableOtherPlatformName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">家庭金融财产状况：</div>
            <div class="info-item-value">{{ detail.familyFinanceStatus }}</div>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">证明材料</div>
        <div class="img-list">
          <el-image
            v-for="item in detail.certifyFileList"
            :key="item.id"
            style="width: 70px; height: 70px"
            :src="item.fileUrl"
            :preview-src-list="certifyFileList"
            :z-index="9999"
          />
        </div>
      </div>
      <div class="group">
        <div class="group-title">实时筹集情况</div>
        <div class="info-item">
          <div class="info-item-label">目标筹集款：</div>
          <div class="info-item-value">{{ detail.targetMoney }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">已经筹集款：</div>
          <div class="info-item-value">{{ detail.alreadyCollectMoney }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">捐款次数：</div>
          <div class="info-item-value">{{ detail.alreadyCollectCount }}</div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">关 闭</el-button>
      <!-- <el-button v-if="pageType !== 'look'" type="primary" size="small" @click="submit">提交</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import { getCollectingDetail, editCollectingDetail } from '../../../utils/api'
import '@/assets/style/common.less'
export default {
  data() {
    return {
      show: false,
      title: '审核',
      id: '',
      detail: null,
      form: {
        checkStatus: 2,
        checkOpinion: '',
        proStatus: 7
      },
      pageType: ''
    }
  },
  computed: {
    projectFileList() {
      return this.detail.projectFileList.map(item => item.fileUrl)
    },
    certifyFileList() {
      return this.detail.certifyFileList.map(item => item.fileUrl)
    }
  },
  methods: {
    open(id, type = '') {
      this.show = true
      this.id = id
      this.getDetail()
      if (type === 'look') {
        this.pageType = 'look'
        this.title = '查看审核'
      } else {
        this.pageType = ''
        this.title = '审核'
      }
    },
    async getDetail() {
      const res = await getCollectingDetail({ id: this.id })
      const { success, data } = res
      if (success) {
        this.detail = data
      }
    },
    async submit() {
      const opts = {
        ...this.detail,
        ...this.form
      }
      const res = await editCollectingDetail(opts)
      const { success } = res
      if (success) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('updateList')
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>
<style lang="less">
  .examine-form {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
  }
  .desc {
    white-space: pre-wrap;
  }
</style>
