<template>
  <div>
    <el-card>
      <el-form ref="searchForm" :model="searchForm" :rules="rules" inline size="mini">
        <div>
          <el-form-item
            label="审核状态:"
          >
            <el-radio-group v-model="searchForm.proStatus" size="small" prop="proStatus">
              <el-radio :label="7">全部</el-radio>
              <el-radio :label="1">筹款中</el-radio>
              <el-radio :label="3">已完成</el-radio>
              <el-radio :label="5">关闭中</el-radio>
              <el-radio :label="4">平台强制关闭</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="求助人:">
            <el-input v-model="searchForm.userName" prop="userName" />
          </el-form-item>
          <el-form-item label="求助编号:">
            <el-input v-model="searchForm.id" prop="id" />
          </el-form-item>
          <el-form-item label="求助标题:">
            <el-input v-model="searchForm.title" prop="title" />
          </el-form-item>
          <el-form-item label="排列等级:">
            <el-select v-model="searchForm.orderLevel" placeholder="请选择排列等级">
              <el-option
                v-for="item in orderLevel"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="求助时间:" prop="daterange">
            <el-date-picker
              v-model="daterange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
            <el-button type="primary" size="mini" @click="resetSearch('searchForm')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        size="mini"
        :data="list"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column type="index" width="55" label="序号" />
        <el-table-column label="求助人" prop="userName" />
        <el-table-column label="求助标题" prop="title" />
        <el-table-column label="求助编号" prop="id" />
        <el-table-column label="收款人（与求助人关系）" prop="relationName" />
        <el-table-column label="目标筹款金额（元）" prop="targetMoney" />
        <el-table-column label="求助时间" prop="createTime" />
        <el-table-column label="联系人" prop="contactUser" />
        <el-table-column label="联系电话" prop="contactPhone" />
        <el-table-column label="状态" prop="proStatus">
          <template slot-scope="{ row }">
            <div>{{ ProStatus[row.proStatus] }}</div>
          </template>
        </el-table-column>
        <el-table-column label="排序等级" prop="orderLevelName" />
        <el-table-column label="操作" fixed="right" width="420">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="showDetail(row.id)">查看</el-button>
            <el-button v-if="!row.urgency" type="text" size="mini" @click="onSetOrderLevel(row.id, 'urgency')">设为急需筹款</el-button>
            <el-button v-if="!row.stick" type="text" size="mini" @click="onSetOrderLevel(row.id, 'stick')">设为置顶</el-button>
            <el-button v-if="row.proStatus !== 3 && row.urgency" type="text" size="mini" @click="onCancelOrderLevel(row.id, 'urgency')">取消急需筹款</el-button>
            <el-button v-if="row.stick" type="text" size="mini" @click="onCancelOrderLevel(row.id, 'stick')">取消置顶</el-button>
            <el-button type="text" size="mini" @click="showProcess(row.id)">项目进展</el-button>
            <el-button v-if="row.proStatus === 1" type="text" size="mini" @click="closeProject(row.id, 'close')">强制关闭</el-button>
            <el-button v-if="row.proStatus === 4" type="text" size="mini" @click="closeProject(row.id, 'open')">开启</el-button>
            <el-button v-if="row.proStatus === 4" type="text" size="mini" @click="show(row.id)">关闭/开启详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        :current-page="searchForm.current"
        :page-size="searchForm.size"
        :total="total"
        layout="total, prev, pager, next, jumper"
        style="padding:20px; margin-bottom: 50px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <project-detail-dialog ref="detailDialog" @updateList="onSearch" />
    <project-control-dialog ref="projectControlDialog" @updateList="onSearch" />
    <project-control-detail-dialog ref="projectControlDetailDialog" />
    <process-dialog ref="processDialog" />
  </div>
</template>

<script>
import { getCollectingList, setOrderLevel, cancelOrderLevel } from '../../utils/api'
import ProjectDetailDialog from './components/ProjectDetailDialog'
import ProjectControlDialog from './components/ProjectControlDialog'
import ProjectControlDetailDialog from './components/ProjectControlDetailDialog'
import ProcessDialog from './components/ProcessDialog.vue'
import moment from 'moment'

export default {
  components: {
    ProjectDetailDialog,
    ProjectControlDialog,
    ProjectControlDetailDialog,
    ProcessDialog
  },
  data() {
    return {
      ProStatus: {
        1: '筹款中',
        3: '已完成',
        4: '平台强制关闭',
        5: '已关闭'
      },
      orderLevel: [],
      daterange: [],
      searchForm: {
        current: 1,
        size: 8,
        proStatus: 7,
        checkStatus: 2,
        orderLevel: '',
        userName: '',
        id: '',
        endDate: '',
        startDate: '',
        title: ''
      },
      rules: {},
      list: [],
      total: 0,
      pages: 0,
      dataListLoading: false
    }
  },
  mounted() {
    this.getList()
    const { orderLevel } = JSON.parse(sessionStorage.getItem('dictList'))
    this.orderLevel = orderLevel
  },
  methods: {
    async onSetOrderLevel(id, orderLevel) {
      let text = ''
      if (orderLevel === 'urgency') {
        text = '是否确认设为急需筹款？'
      } else if (orderLevel === 'stick') {
        text = '是否确认设为置顶？'
      }
      const confirm = await this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const opts = {
        id,
        orderLevel
      }
      if (confirm === 'confirm') {
        const res = await setOrderLevel(opts)
        if (res.success) {
          this.$message.success('设置成功')
          this.onSearch()
        }
      }
    },
    async onCancelOrderLevel(id, orderLevel) {
      let text = ''
      if (orderLevel === 'urgency') {
        text = '是否确认取消急需筹款？'
      } else if (orderLevel === 'stick') {
        text = '是否确认取消置顶？'
      }
      const confirm = await this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const opts = {
        id,
        orderLevel
      }
      if (confirm === 'confirm') {
        const res = await cancelOrderLevel(opts)
        if (res.success) {
          this.$message.success('取消成功')
          this.onSearch()
        }
      }
    },
    showProcess(id) {
      this.$refs.processDialog.open(id)
    },
    show(id) {
      this.$refs.projectControlDetailDialog.open(id)
    },
    closeProject(id, type) {
      this.$refs.projectControlDialog.open(id, type)
    },
    showDetail(id) {
      this.$refs.detailDialog.open(id)
    },
    handleSizeChange(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchForm.current = val
      this.getList()
    },
    async getList() {
      this.dataListLoading = true
      if (this.daterange.length) {
        this.searchForm.startDate = moment(this.daterange[0]).format('YYYY-MM-DD HH:mm:ss')
        this.searchForm.endDate = moment(this.daterange[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.searchForm.startDate = ''
        this.searchForm.endDate = ''
      }
      const opts = { ...this.searchForm }
      if (opts.orderLevel === 'urgency') {
        opts.urgency = 1
        opts.proStatus = 6
      } else if (opts.orderLevel === 'stick') {
        opts.stick = 1
      }

      const res = await getCollectingList(opts)
      this.dataListLoading = false
      const { success, data } = res
      if (success) {
        this.list = data.records
        this.total = data.total
      }
    },
    onSearch() {
      this.searchForm.current = 1
      this.getList()
    },
    resetSearch(formName) {
      this.searchForm.proStatus = 7
      this.searchForm.checkStatus = 2
      this.daterange = []
      this.searchForm.userName = ''
      this.searchForm.relateId = ''
      this.searchForm.title = ''
      this.$refs[formName].resetFields()
      this.searchForm.current = 1
      this.searchForm.orderLevel = ''
      this.getList()
    }
  }
}
</script>

<style>

</style>
