<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-09-22 10:56:28
 * @LastEditors: peter
 * @LastEditTime: 2021-09-22 14:08:56
-->
<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
    :destroy-on-close="true"
  >
    <el-form label-width="100px">
      <el-form-item label="操作原因：">
        <el-input v-model="form.reason" type="textarea" />
      </el-form-item>
      <el-form-item>
        <div v-if="type === 'open'" class="notice">
          一旦开启，小程序那边就会正常展示该捐款内容，请务必核实该捐款是否属实后，再决定是否开启
        </div>
        <div v-if="type === 'close'" class="notice">
          一旦强制，小程序那边将不会像用户展示该捐款内容，请务必核实该捐款是否属实后，再决定是否强制关闭
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">取 消</el-button>
      <el-button type="primary" size="small" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCollectingDetail, editCollectingDetail } from '../../../utils/api'

export default {
  data() {
    return {
      show: false,
      title: '强制关闭',
      id: '',
      type: 'close',
      form: {
        reason: '',
        startClose: 'close'
      }
    }
  },
  methods: {
    open(id, type) {
      this.show = true
      this.id = id
      this.getDetail()
      if (type === 'close') {
        this.title = '强制关闭'
        this.type = 'close'
      } else {
        this.title = '开启'
        this.type = 'open'
      }
    },
    async getDetail() {
      const res = await getCollectingDetail({ id: this.id })
      const { success, data } = res
      if (success) {
        this.form = { ...this.form, ...data }
      }
    },
    async submit() {
      const opts = {
        ...this.form
      }
      if (this.type === 'close') {
        opts.proStatus = 4
        opts.startClose = 'close'
      } else {
        opts.proStatus = 1
        opts.startClose = 'open'
      }

      const res = await editCollectingDetail(opts)
      const { success } = res
      if (success) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('updateList')
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  color: rgba(188, 27, 27, 100);
  font-size: 14px;
  line-height: 1.2;
}
</style>>

