<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-09-22 15:37:27
 * @LastEditors: peter
 * @LastEditTime: 2021-09-24 17:36:32
-->
<template>
  <el-dialog :visible.sync="show" :title="title" center>
    <div v-if="detail" class="dialog-wrapper">
      <div class="group">
        <!-- <div class="group-title">详情说明</div> -->
        <div class="info-item">
          <div class="info-item-label">操作内容：</div>
          <div class="info-item-value">{{ detail.handleContent }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">操作人：</div>
          <div class="info-item-value">{{ detail.handler }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">操作世间：</div>
          <div class="info-item-value">{{ detail.handleTime }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">操作原因：</div>
          <div class="info-item-value">{{ detail.reason }}</div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCollectingDetail } from '../../../utils/api'
import '@/assets/style/common.less'
export default {
  data() {
    return {
      show: false,
      title: '开启/关闭详情',
      id: '',
      detail: null,
      pageType: ''
    }
  },
  methods: {
    open(id) {
      this.show = true
      this.id = id
      this.getDetail()
    },
    async getDetail() {
      const res = await getCollectingDetail({ id: this.id })
      const { success, data } = res
      if (success) {
        this.detail = data
      }
    }
  }
}
</script>
<style lang="less">
.examine-form {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}
</style>
