<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-10-26 13:45:42
 * @LastEditors: peter
 * @LastEditTime: 2021-10-28 10:54:11
-->
<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
  >
    <div class="content">
      <div class="dialog-wrapper">
        <div v-for="item in list" :key="item.id" class="group">
          <div class="info-item">
            <div class="info-item-label">发布时间：</div>
            <div class="info-item-value">{{ item.createTime }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">发布人：</div>
            <div class="info-item-value">{{ item.publisher }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">进展内容：</div>
            <div class="info-item-value">{{ item.content }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">进展图片：</div>
            <div class="info-item-value">
              <div class="img-list">
                <el-image
                  v-for="image in item.fileList"
                  :key="image.id"
                  style="width: 70px; height: 70px"
                  :src="image.fileUrl"
                  :preview-src-list="item.fileList.map(item => item.fileUrl)"
                  :z-index="9999"
                />
              </div>
            </div>
          </div>
        </div>
        <el-empty v-if="!list.length" description="暂无进展" />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProjectProcess } from '../../../utils/api'
import '@/assets/style/common.less'
export default {
  data() {
    return {
      show: false,
      title: '项目进展',
      id: '',
      list: []
    }
  },
  created() {
    Object.assign(this.$data, this.$options.data())
  },
  methods: {
    open(id) {
      this.show = true
      this.id = id
      this.getDetail()
    },
    async getDetail() {
      const res = await getProjectProcess({ helpNo: this.id })
      const { success, data } = res
      if (success) {
        this.list = data
      }
    }
  }
}
</script>
<style lang="less">
.content {
  height: 600px;
  overflow: auto;
}
</style>
